/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-return */
import { Button, CircularProgress, LinearProgress, Theme, useMediaQuery } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Layout } from '@orientaction/components';
import Model1 from '@orientaction/components/Admin/Game/ModelPageUser/Model1';
import Model2 from '@orientaction/components/Admin/Game/ModelPageUser/Model2';
import Model3 from '@orientaction/components/Admin/Game/ModelPageUser/Model3';
import Model4 from '@orientaction/components/Admin/Game/ModelPageUser/Model4';
import Model5 from '@orientaction/components/Admin/Game/ModelPageUser/Model5';
import Model6 from '@orientaction/components/Admin/Game/ModelPageUser/Model6';
import Model7 from '@orientaction/components/Admin/Game/ModelPageUser/Model7';
import { getFromLS } from '@orientaction/utils';
import { find, includes } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apiServiceNative } from '../../../utils/fetchNative';
import style from '../style';

const PlayGame = (props: any) => {
  const classes = style();
  const [warningsListData, setWarningList] = useState('');
  const [openGame, setOpenGame] = useState(false);
  const [gameEdit, setGameEdit] = useState<any>(null);
  const [allPages, setPages] = useState<any>(null);
  const [pageId, setPageId] = useState(0);
  const [page, setPage] = useState<any>(null);
  const token = getFromLS('userToken') || '';
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { id, idLastPage }: any = useParams();
  const [pagesViewed, setPagesViewed] = useState<any>();
  const [progress, setProgress] = useState<number>();
  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const renderModelCondition = (idModele: number, pageToShow: any) => {
    switch (idModele) {
      case 1:
        return <Model1 page={pageToShow} handleChooseDestion={goToDestination} endGame={endGame} />;
      case 2:
        return <Model2 page={pageToShow} handleChooseDestion={goToDestination} endGame={endGame} />;
      case 3:
        return <Model3 page={pageToShow} handleChooseDestion={goToDestination} endGame={endGame} />;
      case 4:
        return <Model4 page={pageToShow} handleChooseDestion={goToDestination} endGame={endGame} />;
      case 5:
        return <Model5 page={pageToShow} handleChooseDestion={goToDestination} endGame={endGame} />;
      case 6:
        return <Model6 page={pageToShow} handleChooseDestion={goToDestination} endGame={endGame} />;
      case 7:
        return <Model7 page={pageToShow} handleChooseDestion={goToDestination} endGame={endGame} />;
      default:
        return;
    }
  };

  const validateArrayObjects = (array: any): any => {
    const errors: any[] = [];
    let hasEndGame = false;
    const validIds = array.map((item: any) => item.id);

    array.forEach((item: any, index: any) => {
      if (item.typeChoice === 'end_game') {
        hasEndGame = true;
      } else if (!item.choice || item.choice.length === 0) {
        errors.push(
          `Erreur dans la page intitulée "${item.title || `P${index + 1}`}": Le choix est vide.`,
        );
      } else {
        item.choice.forEach((choice: any, choiceIndex: any) => {
          if (!choice.destination && !choice.destinationUrl) {
            errors.push(
              `Erreur dans la page intitulée "${item.title || `P${index + 1}`}", choix numéro ${
                choiceIndex + 1
              }: Destination et destinationUrl sont manquants.`,
            );
          }

          if (choice.destination && !validIds.includes(choice.destination)) {
            errors.push(
              `Erreur dans la page intitulée "${item.title || `P${index + 1}`}", choix numéro ${
                choiceIndex + 1
              }: La page de destination ${choice.destination} n'est pas trouvée dans la liste.`,
            );
          }
        });
      }
    });

    if (!hasEndGame) {
      errors.push("Ce jeu n'a pas de fin.");
    }

    return errors;
  };

  useEffect(() => {
    const fetchGameDetails = async () => {
      setLoading(true);
      try {
        const response = await apiServiceNative.get(`/api/game-front-user/${id}`);
        setPages(response?.pages);
        if (response && response.pages && response.pages.length > 0) {
          console.log({ response });
          setGameEdit(response);
          setPagesViewed(response.idViewedPages);
          setProgress(progress);
          if (response?.last_id_page_visited !== 0) {
            setPage(find(response.pages, { id: response?.last_id_page_visited }));
          } else {
            setPage(response.pages[0]);
          }
          // setUrl()
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching game details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameDetails();
  }, [id]);

  useEffect(() => {
    if (allPages?.length > 0) {
      if (validateArrayObjects(allPages)?.length > 0) {
        setOpenGame(true);
        setWarningList(validateArrayObjects(allPages) as any);
      }
    }
  }, [allPages]);

  const goToDestination = async (idDestination: any) => {
    if (idDestination === -1) {
      await restartGameToCover();
      return;
    }
    setPage(find(gameEdit.pages, { id: idDestination }));
  };

  useEffect(() => {
    const updateLastViewPage = async (pageToEdit: any, gameToEdit: any) => {
      let dataToSend: any = { last_id_page_visited: pageToEdit.id, date_consultation: new Date() };
      // console.log({gameEditIdpages: gameToEdit.idViewedPages, pageEdit: pageToEdit.id, check: !gameToEdit.idViewedPages.includes(pageToEdit.id)})
      if (pagesViewed.length === 0 || !includes(pagesViewed, pageToEdit.id)) {
        console.log({ gameEditIdpages: pagesViewed, pageEdit: pageToEdit.id });
        const idViewedPages: any = [...pagesViewed, pageToEdit.id];
        dataToSend = { ...dataToSend, idViewedPages: idViewedPages.join(',') };
      }
      const updatedGameEdit: any = await apiServiceNative.put(
        `/api/update-result-game/${gameEdit?.idResult}`,
        dataToSend,
      );
      setPagesViewed(updatedGameEdit.idViewedPages.split(',').map(Number));
      setProgress(updatedGameEdit.progress);
      console.log({ updatedGameEdit });
    };
    if (page && gameEdit) {
      updateLastViewPage(page, gameEdit);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [page, gameEdit]);

  const endGame = async () => {
    const updatedGameEdit = await apiServiceNative.put(
      `/api/restart-game-result/${gameEdit?.idResult}`,
      {},
    );
    console.log({ updatedGameEdit });
    goBackRessource();
  };

  const goBackRessource = () => {
    if (isXS) {
      sessionStorage.setItem('redirect', JSON.stringify(['seriousgame', gameEdit.idGame]));
    }
    history.push('/mes-ressources');
  };

  const restartGameToCover = async () => {
    const updatedGameEdit = await apiServiceNative.put(
      `/api/restart-game-result/${gameEdit?.idResult}`,
      {},
    );
    history.push(`/game/view/${gameEdit?.idGame}`);
  };

  return (
    <>
      {/*    <ModalCheckGame
        open={openGame}
        onCancel={() => {
          endGame();
        }}
        handleClose={() => {
          setOpenGame(false);
        }}
        title=""
        message=""
        warningsListData={warningsListData as any}
      /> */}
      <Layout
        isaddBackground={true}
        noPadding={false}
        isNew3={true}
        footer={false}
        useHeader={false}
      >
        <div className={classes.containerModel}>
          <div style={{ width: '100%' }}>
            <div className={classes.btnHeader}>
              <div className={classes.btn2} style={{ justifyContent: 'space-between' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={goBackRessource}
                >
                  <KeyboardArrowLeftIcon />
                  <div style={{ marginTop: '3px' }}>Retour {`${isXS ? '' : ' à la liste'}`}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    className={classes.btnOutFilled}
                    style={{ background: 'transparent', padding: '6px 20px' }}
                    onClick={() => restartGameToCover()}
                  >
                    Recommencer
                  </Button>
                </div>
              </div>
            </div>
            {isXS && (
              <div className={classes.progressBar}>
                <LinearProgress
                  classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                  variant="determinate"
                  value={progress}
                />
              </div>
            )}

            {loading ? (
              <CircularProgress
                size={40}
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  zIndex: 3,
                  color: 'white',
                }}
              />
            ) : (
              renderModelCondition(page?.template_game, page)
            )}
            {!isXS && (
              <div className={classes.progressBar}>
                <LinearProgress
                  classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                  variant="determinate"
                  value={progress}
                />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PlayGame;
