/* eslint-disable no-extra-boolean-cast */
import style from './style';
import { Button, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { apiServiceNative } from '../../utils/fetchNative';

const CardRessources = (props: any) => {
  const {
    elem,
    title,
    processReading = 'nothing',
    assignationDate = '',
    readingDate = '',
    linkTo,
    updateDate,
    isEbook = false,
    isRestart = false,
    id,
    idResult,
    idLastPage,
    isMobile = false,
  } = props;
  const classes = style();
  const history = useHistory();
  const relancerGame = async () => {
    if (isRestart) {
      const updatedGameEdit: any = await apiServiceNative.put(
        `/api/update-result-game/${idResult}`,
        {
          isRestart: false,
        }
      );
      console.log({ updatedGameEdit });
    }
    history.push(`/game/view/${id}/${idResult}`);
  };
  return (
    <div>
      {!isMobile && (
        <div
          className={classes.containerCover}
          style={isRestart ? { filter: 'brightness(0.6)' } : {}}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <div
            className={classes.containerElement}
            style={{ width: isEbook ? 202 : 183, height: 247 }}>
            {elem}
          </div>
        </div>
      )}
      {isMobile && (
        <div
          className={classes.containerCover2}
          style={isRestart ? { filter: 'brightness(0.6)' } : {}}>
          <div className={classes.content2}>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
            <div className={classes.containerElement2}>{elem}</div>
          </div>
        </div>
      )}
      <div>
        <Typography
          variant="h4"
          className={classes.titleStatus}
          style={processReading === 'nothing' ? { visibility: 'hidden' } : {}}>
          {processReading}
        </Typography>
      </div>
      <div
        style={{
          ...(isMobile ? { margin: 'auto', width: '70%' } : {}),
        }}>
        {linkTo && (
          <Link to={linkTo}>
            <Button
              className={classes.btnFull}
              style={{ background: 'white' }}
              onClick={() =>
                isRestart ? history.push(`/game/view/${id}`) : console.log('need redirection')
              }>
              Consulter
            </Button>
          </Link>
        )}
        {!linkTo && (
          <Button
            className={!!readingDate ? classes.btnOutFilled : classes.btnFull}
            style={{ background: !!readingDate ? 'transparent' : 'white' }}
            onClick={() =>
              !readingDate
                ? history.push(`/game/view/${id}`)
                : isRestart
                ? relancerGame()
                : history.push(`/game/play/${idResult}/${idLastPage}`)
            }>
            {!readingDate ? 'Commencer' : isRestart ? 'Recommencer' : 'Reprendre la lecture'}
          </Button>
        )}
      </div>
      <div
        style={{
          ...{ marginTop: 10 },
          ...(isMobile ? { marginLeft: 'auto', marginRight: 'auto', width: '70%' } : {}),
        }}>
        {isEbook && (
          <Typography variant="h4" className={classes.textFooter}>
            <span>Assigné le :</span> <span className={classes.spanText}>{assignationDate}</span>
          </Typography>
        )}
        {isEbook && <div className={classes.separator} />}
        {readingDate && (
          <Typography variant="h4" className={classes.textFooter}>
            <span>{'Consulté le : '}</span> <span className={classes.spanText}>{readingDate}</span>
          </Typography>
        )}
        {isEbook && !readingDate && (
          <Typography variant="h4" className={classes.textFooter}>
            <span>Consulté le : </span>{' '}
            <span className={classes.spanText}>Pas encore consulté</span>
          </Typography>
        )}
      </div>
    </div>
  );
};

export default CardRessources;
